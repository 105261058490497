export default {
  data() {
    return {
      modalSchedule: {
        inputTime: '',
        scheduleTime: {
          day: '',
          openHour: '08',
          openMinute: '00',
          closeHour: '17',
          closeMinute: '00',
        },
        modalOption: null,
        modalType: null,
        options: [
          {
            text: 'Senin', value: 'Senin', checked: false, timeStart: '08:00', timeEnd: '17:00',
          },
          {
            text: 'Selasa', value: 'Selasa', checked: false, timeStart: '08:00', timeEnd: '17:00',
          },
          {
            text: 'Rabu', value: 'Rabu', checked: false, timeStart: '08:00', timeEnd: '17:00',
          },
          {
            text: 'Kamis', value: 'Kamis', checked: false, timeStart: '08:00', timeEnd: '17:00',
          },
          {
            text: 'Jumat', value: 'Jumat', checked: false, timeStart: '08:00', timeEnd: '17:00',
          },
          {
            text: 'Sabtu', value: 'Sabtu', checked: false, timeStart: '08:00', timeEnd: '17:00',
          },
          {
            text: 'Minggu', value: 'Minggu', checked: false, timeStart: '08:00', timeEnd: '17:00',
          },
        ],
      },
    }
  },
  methods: {
    openModal: function (option, type) {
      this.modalSchedule.modalOption = option
      this.modalSchedule.modalType = type
      this.modalSchedule.inputTime = type === 'start' ? option.timeStart : option.timeEnd

      this.$bvModal.show('modal')
    },
    changeTime: function () {
      if (this.modalSchedule.modalType === 'start') {
        this.modalSchedule.modalOption.timeStart = this.modalSchedule.inputTime
      } else {
        this.modalSchedule.modalOption.timeEnd = this.modalSchedule.inputTime
      }
      this.$bvModal.hide('modal')
    },
    getOpenTime: function () {
      const time = this.modalSchedule.inputTime
      this.modalSchedule.scheduleTime.openHour = time.split(':')[0]
      this.modalSchedule.scheduleTime.openMinute = time.split(':')[1]
    },
    getCloseTime: function () {
      const time = this.modalSchedule.inputTime
      this.modalSchedule.scheduleTime.closeHour = time.split(':')[0]
      this.modalSchedule.scheduleTime.closeMinute = time.split(':')[1]
    },
    getDay: function(day) {
      this.modalSchedule.scheduleTime.day = day
    },
    handleInitSchedule(scheduleToParse) {
      const schedules = JSON.parse(scheduleToParse)

      for (const update of schedules) {
        const dayName = update.day.toLowerCase()
        const dayIndex = this.modalSchedule.options.findIndex(
          day => day.value.toLowerCase() === dayName,
        )

        if (dayIndex !== -1) {
          this.modalSchedule.options[
              dayIndex
              ].timeStart = `${update.open_hour}:${update.open_minute}`
          this.modalSchedule.options[
              dayIndex
              ].timeEnd = `${update.close_hour}:${update.close_hour}`
          this.modalSchedule.options[dayIndex].checked = true
        }
      }
    },
  },
}
