<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col
      cols="12"
      md="8"
      class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden"
    >
      <h2>Ubah Informasi Rumah Sakit</h2>
    </b-col>
    <b-col cols="6">
      <ul>
        <li>
          <div class="d-block mb-3">
            <label>Nama Rumah Sakit</label>
            <b-form-input
              v-model="field.name"
              placeholder="Nama Rumah Sakit"
              :state="validation.name"
            />
            <b-form-invalid-feedback :state="validation.name">
              {{ fieldErrors.name }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li class="mb-3">
          <div class="d-block mb-3">
            <label>Lokasi Rumah Sakit</label>
            <b-form-input
              v-model="field.address"
              list="results"
              placeholder="Masukan Lokasi Rumah Sakit"
              class="mb-3"
              :state="validation.address"
              @input="searchLocation"
            />
            <datalist id="results">
              <option
                v-for="result in results"
                :key="result.label"
                :value="result.label"
              >
                {{ result.label }}
              </option>
            </datalist>
            <b-form-invalid-feedback :state="validation.address">
              {{ fieldErrors.address }}
            </b-form-invalid-feedback>
          </div>
          <l-map
            :zoom="zoom"
            :center="center"
            :options="{ zoomControl: false }"
            style="width: 100%; height: 200px; position: relative;"
            @click="moveMarker"
          >
            <l-tile-layer :url="url" />
            <l-marker :lat-lng="markerLatLng" />
            <v-geosearch :options="geosearchOptions" />
          </l-map>
        </li>
        <li class="mb-3">
          <label for="fileInput">Foto
            <div class="previewbox">
              <input
                id="fileInput"
                ref="fileInput"
                class="input-file mb-3"
                type="file"
                :state="validation.image"
                @change="vfileAdded( $event )"
              >
              <img v-if="urlImg" :src="urlImg" style="z-index: -1;"/>
              <p v-else>
                Klik disini untuk menelusuri file gambar
              </p>
            </div>
            <b-button
              variant="danger"
              class="mt-3"
              @click="removeFile"
            >Remove File</b-button>
          </label>
          <b-form-invalid-feedback :state="validation.image">
            {{ fieldErrors.image }}
          </b-form-invalid-feedback>
        </li>
      </ul>
    </b-col>
    <b-col cols="6">
      <ul>
        <li class="mb-3">
          <label>Layanan</label>
          <b-form-input
            v-model="field.service"
            placeholder="Masukan Layanan Rumah Sakit"
            class="mb-3"
            :state="validation.service"
          />
          <b-form-invalid-feedback :state="validation.service">
            {{ fieldErrors.service }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label>Deskripsi</label>
          <b-form-input
            v-model="field.description"
            placeholder="Masukan Deskripsi Rumah Sakit"
            class="mb-3"
            :state="validation.description"
          />
          <b-form-invalid-feedback :state="validation.description">
            {{ fieldErrors.description }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label>Kontak Rumah Sakit</label>
          <b-form-input
            v-model="field.contact"
            placeholder="Masukan Kontak Rumah Sakit"
            class="mb-3"
            :state="validation.contact"
          />
          <b-form-invalid-feedback :state="validation.contact">
            {{ fieldErrors.contact }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label>Email Rumah Sakit</label>
          <b-form-input
            v-model="field.email"
            placeholder="Masukan Kontak Rumah Sakit"
            class="mb-3"
            :state="validation.email"
          />
          <b-form-invalid-feedback :state="validation.email">
            {{ fieldErrors.email }}
          </b-form-invalid-feedback>
        </li>
        <li class="mb-3">
          <label>Jadwal Operasional</label>
          <b-form-group>
            <div
              v-for="option in modalSchedule.options"
              :key="option.value"
              class="d-flex align-items-center position-relative"
            >
              <b-form-checkbox
                :key="option.value"
                v-model="option.checked"
                name="days"
                class="mr-2 mb-3"
              >
                {{ option.text }}
              </b-form-checkbox>
              <div
                class="d-flex position-absolute mb-3"
                style="left: 150px"
              >
                <b-button
                  size="sm"
                  variant="dark"
                  class="mr-2 btn-ghost"
                  :disabled="!option.checked"
                  @click="openModalByType(option, 'start')"
                >{{ option.timeStart }}
                </b-button>
                <span>-</span>
                <b-button
                  size="sm"
                  variant="dark"
                  class="ml-2 btn-ghost"
                  :disabled="!option.checked"
                  @click="openModalByType(option, 'end')"
                >{{ option.timeEnd }}
                </b-button>
              </div>
            </div>
          </b-form-group>
        </li>
      </ul>
    </b-col>
    <b-col class="d-flex justify-content-end mb-3">
      <b-button
        variant="danger"
        class="mr-3"
        @click="$router.go(-1)"
      >
        Batal
      </b-button>
      <b-button
        variant="info"
        @click="updateHospital"
      >
        Simpan
      </b-button>
    </b-col>
    <b-modal
      id="modal"
      centered
      cancel-variant="text"
      header-class="align-items-center"
      hide-footer
    >
      <template>
        <h5 class="mb-0">
          Jam {{ modalSchedule.modalType === "start" ? "Masuk" : "Pulang" }}
        </h5>
      </template>

      <b-form-input
        v-model="modalSchedule.inputTime"
        type="time"
        @change="openByModalType"
      />

      <div class="d-flex justify-content-end flex-wrap mt-32">
        <b-button
          variant="text"
          @click="$bvModal.hide('modal')"
        >
          Close
        </b-button>

        <b-button
          class="ml-16"
          variant="primary"
          @click="changeTimeFromModal"
        >
          Save changes
        </b-button>
      </div>
    </b-modal>
  </b-row>
</template>
<script>
import {
  BCol,
  BRow,
  BButton,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
  BModal,
  BToast,
  BFormInvalidFeedback,
  BFormFile,
} from 'bootstrap-vue'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import { latLng } from 'leaflet'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import VGeosearch from 'vue2-leaflet-geosearch'
import KelolaRumahSakitService from '../../../../api/managehospital/manageHospitalAPI'
import scheduleModalMixins from '../../../../mixins/scheduleModalMixins'

export default {
  name: 'EditHospital',
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormFile,
    LMap,
    LMarker,
    LTileLayer,
    VGeosearch,
    BToast,
    BModal,
  },
  mixins: [scheduleModalMixins],
  data() {
    return {
      results: [],
      field: {
        name: '',
        service: '',
        address: '',
        contact: '',
        email: '',
        description: '',
        image: null,
      },
      fieldErrors: {
        name: undefined,
        service: undefined,
        address: undefined,
        contact: undefined,
        email: undefined,
        description: undefined,
        image: undefined,
      },
      currentInfoRumahSakit: null,
      urlImg: '',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 25,
      center: [51.505, -0.159],
      geosearchOptions: {
        provider: new OpenStreetMapProvider(),
      },
      markerLatLng: {
        lat: 0,
        lng: 0,
      },
      loadingMap: true,
      withPopup: latLng(-6.919959, 107.618084),
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: latLng(-6.919959, 107.618084),
      mapOptions: {
        zoomSnap: 0.5,
      },
    }
  },
  computed: {
    validation() {
      return {
        id: this.fieldErrors.id ? false : null,
        name: this.fieldErrors.name ? false : null,
        service: this.fieldErrors.service ? false : null,
        description: this.fieldErrors.description ? false : null,
        address: this.fieldErrors.address ? false : null,
        contact: this.fieldErrors.contact ? false : null,
        email: this.fieldErrors.email ? false : null,
        image: this.fieldErrors.image ? false : null,
      }
    },
  },
  watch: {
    'field.name': function (newValue) {
      if (newValue) {
        this.fieldErrors.name = null
      }
    },
    'field.service': function (newValue) {
      if (newValue) {
        this.fieldErrors.service = null
      }
    },
    'field.description': function (newValue) {
      if (newValue) {
        this.fieldErrors.description = null
      }
    },
    'field.address': function (newValue) {
      if (newValue) {
        this.fieldErrors.address = null
      }
    },
    'field.contact': function (newValue) {
      if (newValue) {
        this.fieldErrors.contact = null
      }
    },
    'field.email': function (newValue) {
      if (newValue) {
        this.fieldErrors.email = null
      }
    },
    'field.image': function (newValue) {
      if (newValue) {
        this.fieldErrors.image = null
      }
    },
  },
  created() {
    this.getDetailRumahSakit()
  },
  methods: {
    updateHospital() {
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('id', this.currentInfoRumahSakit.id)
      formData.append('name', this.field.name)
      formData.append('service', this.field.service)
      formData.append('description', this.field.description)
      formData.append('address', this.field.address)
      formData.append('contact', this.field.contact)
      formData.append('email', this.field.email)
      formData.append('latitude', this.markerLatLng.lat)
      formData.append('longitude', this.markerLatLng.lng)

      if (this.field.image) {
        formData.append('image', this.field.image)
      }

      KelolaRumahSakitService.updateHospital(formData).then(response => {
        if (response.data.code === 200) {
          this.$router.go(-1)

          setTimeout(() => {
            // Show a success toast
            this.$bvToast.toast('Info Detail Rumah Sakit Berhasil DiEdit', {
              title: 'Success',
              variant: 'success',
              solid: true,
            })
          }, 500)
        }
      }).catch(error => {
        if (error.response) {
          const errorData = error.response.data
          if (errorData.code === 400) {
            const errorMessage = errorData.message.split('\\n')

            errorMessage.forEach(msg => {
              if (msg.includes('id')) {
                this.fieldErrors.id = msg
              } else if (msg.includes('name')) {
                this.fieldErrors.name = msg
              } else if (msg.includes('service')) {
                this.fieldErrors.service = msg
              } else if (msg.includes('address')) {
                this.fieldErrors.address = msg
              } else if (msg.includes('contact')) {
                this.fieldErrors.contact = msg
              } else if (msg.includes('email')) {
                this.fieldErrors.email = msg
              } else if (msg.includes('description')) {
                this.fieldErrors.description = msg
              } else if (msg.includes('image')) {
                this.fieldErrors.image = msg
              }
            })

            this.isLoading = false
          }
        } else {
          console.error(error)
        }
      })

      const mapSchedule = this.modalSchedule.options
        .filter(e => e.checked === true)
        .map(schedule => ({
          day: schedule.value,
          open_hour: schedule.timeStart.split(':')[0],
          open_minute: schedule.timeStart.split(':')[1],
          close_hour: schedule.timeEnd.split(':')[0],
          close_minute: schedule.timeEnd.split(':')[1],
        }))

      const mapScheduleData = {
        hospital_id: this.currentInfoRumahSakit.id,
        schedule: mapSchedule,
      }

      KelolaRumahSakitService.updateHospitalSchedule(mapScheduleData)
    },
    getDetailRumahSakit() {
      KelolaRumahSakitService.get().then(response => {
        this.currentInfoRumahSakit = response.data.data

        this.field.name = this.currentInfoRumahSakit.name
        this.field.address = this.currentInfoRumahSakit.address
        this.field.service = this.currentInfoRumahSakit.service
        this.field.description = this.currentInfoRumahSakit.description
        this.field.email = this.currentInfoRumahSakit.email
        this.field.contact = this.currentInfoRumahSakit.contact

        this.center[0] = response.data.data.latitude
        this.center[1] = response.data.data.longitude

        this.markerLatLng.lat = response.data.data.latitude
        this.markerLatLng.lng = response.data.data.longitude

        this.handleInitSchedule(this.currentInfoRumahSakit.schedule)
      }).catch(err => console.error(err))
    },
    async searchLocation() {
      const { provider } = this.geosearchOptions
      this.results = await provider.search({ query: this.field.address })

      if (this.results && this.results[0]) {
        this.markerLatLng = {
          lat: this.results[0].y,
          lng: this.results[0].x,
        }
      }

      this.center = this.markerLatLng
    },
    moveMarker(e) {
      this.markerLatLng.lat = e.latlng.lat
      this.markerLatLng.lng = e.latlng.lng
      this.currentInfoRumahSakit.address = `${this.markerLatLng.lat}, ${this.markerLatLng.lng}`
    },
    vfileAdded(event) {
      this.field.image = event.target.files[0];
      this.urlImg = URL.createObjectURL(this.field.image);
    },
    removeFile() {
      this.$refs.fileInput.value = ''
    },
    openByModalType() {
      return this.modalSchedule.modalType === 'start' ? this.getOpenTime() : this.getCloseTime()
    },
    changeTimeFromModal() {
      return this.changeTime()
    },
    openModalByType(option, type) {
      return this.openModal(option, type)
    },
  },
}
</script>

<style scoped>
.previewbox {
  width: 425px;
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px;
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  width: 425px;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.previewbox:hover {
  background: lightblue;
  z-index: 3;
}

.previewbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
